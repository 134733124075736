<template>
  <div v-if="weatherTimestamp !== '' && !loading" class="wetteranzeige">
    <div class="grid-container">
      <div class="weather-icon">
        <em :class="currentData?.forecast?.val ? 'mdi mdi-' + forecastIconMatch(currentData?.forecast?.val) : ''"></em>
      </div>
      <div class="temperatur">{{ currentData.temperature.out.toFixed(1) }}°C</div>
    </div>

    <div class="date-time-container">
      <div class="date">{{ printWeekday(currentTimestamp) }} , {{ currentTimestamp.toLocaleDateString("de-DE") }},</div>
      <div class="time">
        {{ currentTimestamp.toLocaleTimeString() }}
        {{ printTimeZone(weatherTimestamp) }}
      </div>
      <div class="forecast">
        6 Stunden Wettervorhersage:
        {{ locale.weatherData.forecast[currentData.forecast.val] }}
      </div>
    </div>
    <div class="data grid-container">
      <div class="data-point">Gefühlte Temperatur</div>
      <div>
        {{ currentData?.feltTemp ? currentData.feltTemp + "°C" : "---" }}
      </div>
      <div class="data-point">Luftdruck</div>
      <div>{{ currentData.baro.toFixed() }} hPa</div>

      <div class="data-point">Luftdrucktrend</div>
      <div>{{ locale.weatherData.barotrend[currentData.barotrend] }}</div>

      <div class="data-point">Luftfeuchtigkeit</div>
      <div>{{ currentData.humidity.out }} %</div>

      <div class="data-point">Windrichtung</div>
      <div>{{ currentData.wind.dir.text }}</div>

      <div class="data-point">Windgeschwindigkeit</div>
      <div>{{ currentData.wind.speed.kmh }} km/h</div>

      <div class="data-point">Windgeschwindigkeit (10 Minuten)</div>
      <div>{{ currentData.wind.avg.kmh }} km/h</div>

      <div class="data-point">Regenrate</div>
      <div>
        {{ currentData.rain.rate !== null ? Math.round(currentData.rain.rate) + " mm" : "N/A" }}
      </div>

      <div class="data-point">Regen (Tag)</div>
      <div>
        {{ currentData.rain.day !== null ? Math.round(currentData.rain.day) + " mm" : "N/A" }}
      </div>

      <div class="data-point">Regen (Monat)</div>
      <div>
        {{ currentData.rain.month !== null ? Math.round(currentData.rain.month) + " mm" : "N/A" }}
      </div>

      <div class="data-point">Regen (Jahr)</div>
      <div>
        {{ currentData.rain.year !== null ? Math.round(currentData.rain.year) + " mm" : "N/A" }}
      </div>

      <div class="data-point">Sonneneinstrahlung</div>
      <div>{{ currentData.sun.rad }} Watt/qm</div>

      <div class="data-point">UV-Index</div>
      <div>{{ currentData.sun.uv }}</div>

      <div class="data-point">Sonnenaufgang</div>
      <div>{{ currentData.sun.rise }} Uhr</div>

      <div class="data-point">Sonnenuntergang</div>
      <div>{{ currentData.sun.set }} Uhr</div>
    </div>
  </div>
  <div class="no-weather-data" v-if="loading">
    <loading-component :message="loadingMessage" />
  </div>
</template>

<script>
import deLocale from "@/locales/de.json";
import LoadingComponent from "@/components/miscellaneous/LoadingComponent.vue";

export default {
  props: {
    weatherData: Object,
    weatherTimestamp: String,
    loading: Boolean,
    loadingMessage: String,
  },
  components: {
    LoadingComponent,
  },
  data() {
    return {
      currentData: {},
      currentTimestamp: {},
      locale: deLocale,
      dataloading: true,
    };
  },
  watch: {
    dataloading: function (newVal) {
      this.dataloading = newVal;
    },

    weatherTimestamp: function (newVal) {
      this.currentTimestamp = newVal;
    },
  },
  sockets: {
    currentData: function (data) {
      this.currentData = data;
    },
  },
  methods: {
    forecastIconMatch(index) {
      let icon;
      switch (index) {
        case 2:
          icon = "weather-cloudy";
          break;
        case 3:
          icon = "weather-rainy";
          break;
        case 6:
          icon = "weather-partly-cloudy";
          break;
        case 7:
          icon = "weather-partly-rainy";
          break;
        case 8:
          icon = "weather-sunny";
          break;
        case 18:
          icon = "weather-snowy";
          break;
        case 19:
          icon = "weather-snowy-rainy";
          break;
        case 22:
          icon = "weather-partly-snowy";
          break;
        case 23:
          icon = "weather-partly-snowy-rainy";
          break;
      }
      return icon;
    },
    printWeekday(timeStemp_in) {
      const date = new Date(timeStemp_in).getDay();
      switch (date) {
        case 0:
          return "Sonntag";
        case 1:
          return "Montag";
        case 2:
          return "Dienstag";
        case 3:
          return "Mittwoch";
        case 4:
          return "Donnerstag";
        case 5:
          return "Freitag";
        case 6:
          return "Samstag";
        default:
          return "NaN";
      }
    },
    printTimeZone(timeStemp_in) {
      if (timeStemp_in.getTimezoneOffset() == -60) {
        return "MEZ";
      } else {
        return "MESZ";
      }
    },
  },
  created() {
    this.currentData = this.weatherData.data || {};
    this.currentTimestamp = this.weatherTimestamp || "";
  },
};
</script>

<style scoped lang="scss">
.wetteranzeige {
  display: grid;
  @media screen and (min-width: $tabletSize) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: $desktopSize) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid $hs-blue-primary 3px;
  }
}
.weather-icon {
  color: $hs-blue-primary;
  align-self: center;
  text-align: right;
  padding: 0 16px;
  font-size: 5rem;
  @media screen and (min-width: $desktopSize) {
    font-size: 6rem;
  }
}
.temperatur {
  color: $hs-blue-primary;
  align-self: center;
  padding: 0 16px;
  font-size: 3rem;
  @media screen and (min-width: $desktopSize) {
    font-size: 3rem;
  }
}
.forecast,
.date,
.time {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
}
.grid-container {
  margin: 32px 0;
  display: grid;
  grid-gap: 0.5rem 1.5rem;
  grid-template-columns: 1fr 1fr;
}
.data {
  grid-template-columns: 150px 90px;
  justify-content: center;
  @media screen and (min-width: $tabletSize) {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
.data-point {
  text-align: right;
}
.no-weather-data {
  align-self: center;
  text-align: center;
  @media screen and (min-width: $tabletSize) {
    grid-column: 1/3;
  }
  @media screen and (min-width: $desktopSize) {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid $hs-blue-primary 3px;
    height: 100%;
  }
}
</style>
