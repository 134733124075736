<template>
  <div class="grid-container">
    <div class="weather-stats">
      <weatherdisplay-component
        :weatherData="weatherData"
        :weatherTimestamp="weatherTimestamp"
        :loading="loading"
        :loadingMessage="loadingMessage"
      />
    </div>
    <div class="cameras-wrapper">
      <h2>Wetter</h2>
      <tab-nav-component :navigation="navigation" />
      <div class="cameras">
        <weather-cam-component v-if="$route.query.wetter === 'wetter-cam' || $route.query.wetter === undefined" />
        <!-- <stream-component :link="weatherStream" v-if="$route.query.wetter === 'wetter-stream'" /> -->
        <stream-component :link="gullStream" v-if="$route.query.wetter === 'moewen-stream'" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TabNavComponent from "@/components/layout/TabNavComponent";
import WeatherdisplayComponent from "@/components/home/WeatherdisplayComponent";
import WeatherCamComponent from "@/components/home/WeatherCamComponent";
import StreamComponent from "@/components/home/StreamComponent.vue";

export default {
  components: {
    TabNavComponent,
    WeatherdisplayComponent,
    WeatherCamComponent,
    StreamComponent,
  },
  data() {
    return {
      weatherStream: process.env.VUE_APP_BACKEND_URL + "/api/stream/weather/stream.mjpg",
      gullStream: process.env.VUE_APP_BACKEND_URL + "/api/stream/gull/stream.mjpg",
      weatherData: {},
      loading: true,
      weatherTimestamp: "",
      extraInfo: false,
      rnd: 0,
      currentImageURL: process.env.VUE_APP_BACKEND_URL + "/images/current/webcam.jpg",
      loadingMessage: "Daten konnten nicht geladen werden \n Wir versuchen es gleich erneut.",
      navigation: [
        { name: "wetter", param: "wetter-cam", linkText: "Wetter-Cam" },
        // { name: "wetter", param: "wetter-stream", linkText: "Wetter-Stream" },
        { name: "wetter", param: "moewen-stream", linkText: "Möwen-Stream" },
      ],
    };
  },

  created() {
    axios
      .get("/data")
      .then((response) => {
        if (response) {
          response.data.ts *= 1000;
          this.weatherData = response;
          this.weatherTimestamp = new Date(response.data.ts);
          this.loading = false;
        }
      })
      .catch((error) => {
        console.error("request failed", error);
        this.weatherData = {};
        this.weatherTimestamp = "";
      });
  },
};
</script>

<style lang="scss" scoped>
.grid-container {
  @media screen and (min-width: $desktopSize) {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: minmax(320px, 500px) minmax(600px, 1fr);
  }
}
.weather-stats {
  display: grid;
}
.cameras-wrapper {
  display: grid;
  grid-row-gap: 24px;
}
.cameras {
  display: grid;
  align-items: end;
}
h2 {
  display: none;
  @media screen and (min-width: $desktopSize) {
    display: block;
    margin: 80px 0 0 0;
  }
}
</style>
