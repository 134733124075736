<template>
  <div class="container">
    <div class="inputBox selectBox selectBox1">
      <span class="inputTitle">Tageszeit</span>

      <select class="input" :id="'select1'" v-model="params.dayPeriod">
        <option value disabled>Optional</option>
        <option v-for="(option, index) in dayPeriods" :key="index" :value="option.value">
          {{ option.label }}
        </option>
      </select>
      <hr class="selectionLine" />
    </div>
    <div class="inputBox selectBox selectBox2">
      <span class="inputTitle">Blickwinkel</span>
      <select class="input" :id="'select2'" v-model="params.preset">
        <option value>Optional</option>
        <option v-for="(option, index) in presets" :key="index" :value="option.value">
          {{ option.label }}
        </option>
      </select>
      <hr class="selectionLine" />
    </div>

    <div class="inputBox dateBox dateBox1" :class="this.wrongStartDate ? 'wrongBox' : ''">
      <span class="inputTitle">Anfangsdatum</span>
      <input class="input" ref="picker1" v-model="params.from" />
      <hr class="selectionLine" />
    </div>

    <div class="inputBox dateBox dateBox2" :class="this.wrongEndDate ? 'wrongBox' : ''">
      <span class="inputTitle">Enddatum</span>
      <input class="input" ref="picker2" v-model="params.to" />
      <hr class="selectionLine" />
    </div>

    <div class="inputBox selectBox intervalBox1">
      <span class="inputTitle">Intervall</span>
      <select class="input" :id="'select1'" v-model="params.interval">
        <option v-for="(intervalValue, index) in intervalValues" :key="index" :value="intervalValue.value">
          {{ intervalValue.label }}
        </option>
      </select>
      <hr class="selectionLine" />
    </div>
    <div class="inputBox selectBox intervalBox2">
      <span class="inputTitle">Einheit</span>
      <select class="input" :id="'select1'" v-model="params.intervalUnit">
        <option v-for="(intervalUnit, index) in intervalUnits" :key="index" :value="intervalUnit.value">
          {{ intervalUnit.label }}
        </option>
      </select>
      <hr class="selectionLine" />
    </div>
    <button class="submit-button" :disabled="disableSubmit" :id="'go'" @click="getImages({ reset: true })">
      <span>Abrufen</span>
    </button>
  </div>
  <p class="images-found" v-if="imageCount !== ''">{{ imageCount === "0" ? "Keine" : imageCount }} Bilder gefunden</p>
  <h3 v-if="imageCount === '' && params.hasRequested" :images="images">Keine Bilder vorhanden</h3>
  <tile-gallery-component v-if="imageCount !== ''" :images="images" />
  <loading-component v-if="params.loading" message="" />
  <button v-if="imageCount > images.length" class="more-button submit-button" @click="getImages({ reset: false })">
    Mehr
  </button>
</template>

<script>
import TileGalleryComponent from "@/components/gallery/TileGalleryComponent";
import LoadingComponent from "@/components/miscellaneous/LoadingComponent";
import axios from "axios";
const { DateTime } = require("luxon");
import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js";
require("flatpickr/dist/themes/light.css");
flatpickr.localize(German);

export default {
  components: { TileGalleryComponent, LoadingComponent },
  data() {
    return {
      images: [],
      imageCount: "",
      params: {
        dayPeriod: "",
        preset: "",
        from: DateTime.now().minus({ days: 1 }).toISO(),
        to: DateTime.now().toISO(),
        intervalUnit: "min",
        interval: "1",
        take: 30,
        skip: 0,
        loading: false,
        hasRequested: false,
      },
      startDayOfData: "2007-07-29T00:00",
      endDayOfData: DateTime.now().toISO(),
      dayPeriods: [
        {
          label: "Sonnenaufgang",
          value: "sunrise",
        },
        {
          label: "Morgens",
          value: "morning",
        },
        {
          label: "Mittags",
          value: "midday",
        },
        {
          label: "Nachmittags",
          value: "afternoon",
        },
        {
          label: "Sonnenuntergang",
          value: "sunset",
        },
        {
          label: "Nachts",
          value: "night",
        },
      ],
      presets: [
        {
          label: "Alle",
          value: 1,
        },
        {
          label: "Lutherkirche",
          value: 2,
        },
        {
          label: "O-Gebäude",
          value: 3,
        },
        {
          label: "N-Hof",
          value: 4,
        },
        {
          label: "N-Dach",
          value: 5,
        },
        {
          label: "Hochheim-Kirche",
          value: 6,
        },
        {
          label: "Alzeier Straße",
          value: 7,
        },
        {
          label: "Klinikum",
          value: 8,
        },
        {
          label: "Möwennest",
          value: 8,
        },
      ],
      intervalUnits: [
        {
          label: "Minuten",
          value: 'min',
        },
        {
          label: "Stunden",
          value: "hou",
        },
        {
          label: "Tage",
          value: "day",
        },
        {
          label: "Monate",
          value: "mon",
        },
        {
          label: "Jahre",
          value: "yea",
        },
      ],
      intervalValues: [
        {
          label: "1",
          value: 1,
        },
        {
          label: "2",
          value: 2,
        },
        {
          label: "5",
          value: 5,
        },
        {
          label: "10",
          value: 10,
        },
        {
          label: "30",
          value: 30,
        },
      ],
    };
  },
  computed: {
    disableSubmit() {
      return this.params.loading || this.wrongStartDate || this.wrongEndDate;
    },
    wrongStartDate() {
      return this.params.from == "" || this.endBeforeStartDate;
    },
    wrongEndDate() {
      return this.params.to == "" || this.endBeforeStartDate;
    },
    endBeforeStartDate() {
      return this.params.from > this.params.to;
    },
  },
  methods: {
    getImages({ reset }) {
      this.params.loading = true;
      if (reset === true) {
        this.params.skip = 0;
        this.images = [];
      }

      let fromFormatted = this.params.from
      let toFormatted = this.params.to

      const requestParams = {
            from: fromFormatted,
            to: toFormatted,
            intervalUnit: this.params.intervalUnit,
            interval: this.params.interval,
            take: this.params.take,
            skip: this.params.skip,
      }
      if(this.dayPeriod !== "") {
        requestParams.dayPeriod = this.dayPeriod
      }
      if(this.preset !== "") {
        requestParams.preset = this.preset
      }
      axios
        .get("/images", {
          params: requestParams
        })
        .then(({ data }) => {
          if (typeof data.data[0]?.path === "string") {
            this.images = this.images.concat(data.data);
            this.imageCount = data.count;
            this.params.skip += this.params.take;
            this.params.loading = false;
            this.params.hasRequested = true;
          }
        })
        .catch((error) => {
          this.params.loading = false;
          console.error(error);
          this.params.hasRequested = true;
        });
    },
  },
  mounted() {
    flatpickr(this.$refs.picker1, {
      disableMobile: "true",
      enableTime: true,
      altInput: true,
      altFormat: "d.m.Y H:i",
      enable: [
        {
          from: "2007-07-29T00:00",
          to: new Date().toISOString(),
        },
      ],
    });

    flatpickr(this.$refs.picker2, {
      disableMobile: "true",
      enableTime: true,
      altInput: true,
      altFormat: "d.m.Y H:i",
      enable: [
        {
          from: "2007-07-29T00:00",
          to: new Date().toISOString(),
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  margin-bottom: 80px;
  width: 100%;
  row-gap: 18px;
  column-gap: 8px;
  grid-template-columns: repeat(12, [col-start] 1fr);
  @media screen and (min-width: $tabletSize) {
    column-gap: 16px;
  }
}
.input {
  border: none;
  background-color: $lightest-gray;
  font-size: 11px;
  width: 100%;
}
.inputBox {
  height: 64px;
  padding: 8px;
  box-sizing: border-box;
  background-color: $lightest-gray;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  border-radius: 8px 8px 0px 0px;
}
.inputTitle {
  font-size: 12px;
  margin-bottom: 8px;
  display: block;
}
.selectBox {
  font-size: 11px;
  display: block;
  border-width: 0;
  background-color: $lightest-gray;
}
.selectBox1 {
  grid-column: 1/13;
  @media screen and (min-width: $tabletSize) {
    grid-column: 1/7;
  }
  @media screen and (min-width: $desktopSize) {
    grid-column: 1/4;
  }
}
.selectBox2 {
  grid-column: 1/13;
  @media screen and (min-width: $tabletSize) {
    grid-column: 7/13;
  }
  @media screen and (min-width: $desktopSize) {
    grid-column: 4/7;
  }
}
.dateBox1 {
  grid-column: 1/7;
  @media screen and (min-width: $tabletSize) {
    grid-column: 1/7;
  }
  @media screen and (min-width: $desktopSize) {
    grid-column: 7/10;
  }
}
.dateBox2 {
  grid-column: 7/13;
  @media screen and (min-width: $tabletSize) {
    grid-column: 7/13;
  }
  @media screen and (min-width: $desktopSize) {
    grid-column: 10/13;
  }
}
.selectionLine {
  height: 1.5px;
  margin-bottom: 4px;
  border-width: 0;
  color: gray;
  background-color: gray;
}
.intervalBox1 {
  grid-column: 1/7;
  @media screen and (min-width: $tabletSize) {
    grid-column: 1/4;
  }
  @media screen and (min-width: $desktopSize) {
    grid-column: 1/4;
  }
}
.intervalBox2 {
  grid-column: 7/13;
  @media screen and (min-width: $tabletSize) {
    grid-column: 4/7;
  }
  @media screen and (min-width: $desktopSize) {
    grid-column: 4/7;
  }
}
.images-found {
  text-align: center;
}
.submit-button {
  grid-column: 1/13;
  @media screen and (min-width: $tabletSize) {
    grid-column: 10/13;
  }
  @media screen and (min-width: $desktopSize) {
    grid-column: 10/13;
  }
}
.more-button {
  width: 100%;
  @media screen and (min-width: $tabletSize) {
    width: 20%;
    margin: 80px auto 0;
    display: block;
  }
}
.wrongBox {
  border: 1px solid red;
}
</style>

