<template>
  <div class="grid-container">
    <div>
      <h2 class="heading projectHeading">Das Projekt</h2>
      <p>
        Das Projekt „Wetterstation an der Hochschule Worms“ wurde von den Studenten Jörg Schuster und Mounir Guiche im
        Jahr 2007 gestartet. Die Installation der Wetterstation und Webcam wurde auf dem Dach des N-Gebäudes der
        Hochschule Worms vorgenommen. Die gesammelten Wetterdaten und -bilder finden Sie auf dieser Website.
      </p>
      <p>
        Die Website wurde dann 2020 im Rahmen des "Team Orientiertes Projekts" (TOP) von Studenten aus dem Fachbereich
        Informatik vollständig überarbeitet. Dabei wurde auch die defekte Webcam ausgetauscht. Im Jahr 2021 wurde
        während des TOP die Versionen v1, v2 und v3 der Wetter-Station zusammengeführt, überarbeitet und neue
        Funktionalitäten hinzugefügt.
      </p>
      <p>
        Die Wetterstation kann neben Temperatur, Luftdruck, Luftfeuchtigkeit, Niederschlagsmenge, Sonneneinstrahlung,
        Windrichtung und Windgeschwindigkeit auch den UV-Index messen.
      </p>
    </div>
    <div class="mapContainer">
      <h3 class="heading mapHeading">Standort der Wetterstation</h3>
      <iframe
        id="mapFrame"
        title="OpenStreetMap of Weatherstation"
        height="250"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        src="https://www.openstreetmap.org/export/embed.html?bbox=8.343485891819002%2C49.633946820799665%2C8.34704250097275%2C49.63614257001446&amp;layer=mapnik&amp;marker=49.63504470778157%2C8.345264196395874"
      ></iframe>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.grid-container {
  margin: 32px 0;
  display: grid;
  grid-gap: 0.5rem 1.5rem;
  grid-template-columns: 1fr;
  @media screen and (min-width: $tabletSize) {
    grid-template-columns: 2fr 1fr;
  }
}
.heading {
  text-align: center;
  color: $medium-gray;
}
.projectHeading {
  font-size: xx-large;
}
.mapContainer {
  display: grid;
  align-items: center;
  @media screen and (min-width: $tabletSize) {
    border: solid $hs-blue-primary 3px;
  }
}
iframe {
  margin-left: -16px;
  width: 100vw;
  border: none;
  @media screen and (min-width: $tabletSize) {
    margin: 16px;
    width: auto;
  }
}
</style>
