<template>
  <div class="full-statistics-wrapper">
    <div class="data-container">
      <!-- Props -->
      <h3 class="heading prop">Parameter</h3>
      <div v-for="(item, index) in prop" :key="index" class="prop" :class="item">{{ item }}</div>
      <!-- Day -->
      <h3 class="heading day lightest-gray">Tag</h3>
      <div
        v-for="(item, index) in day"
        :key="index"
        class="data-field-wrapper day lightest-gray"
        @mouseover="handleMouseenter"
        @mouseleave="handleMouseleave"
      >
        <data-field-component :item="item" />
      </div>
      <!-- Week -->
      <h3 class="heading week lightest-gray">Woche</h3>
      <div
        v-for="(item, index) in week"
        :key="index"
        class="data-field-wrapper week lightest-gray"
        @mouseover="handleMouseenter"
        @mouseleave="handleMouseleave"
      >
        <data-field-component :item="item" />
      </div>
      <!-- Month -->
      <h3 class="heading month lightest-gray">Monat</h3>
      <div
        v-for="(item, index) in month"
        :key="index"
        class="data-field-wrapper month lightest-gray"
        @mouseover="handleMouseenter"
        @mouseleave="handleMouseleave"
      >
        <data-field-component :item="item" />
      </div>
      <!-- Year -->
      <h3 class="heading year lightest-gray">Jahr</h3>
      <div
        v-for="(item, index) in year"
        :key="index"
        class="data-field-wrapper year lightest-gray"
        @mouseover="handleMouseenter"
        @mouseleave="handleMouseleave"
      >
        <data-field-component :item="item" />
      </div>
      <!-- allTime -->
      <h3 class="heading allTime lightest-gray">Seit Beginn</h3>
      <div
        v-for="(item, index) in allTime"
        :key="index"
        class="data-field-wrapper allTime lightest-gray"
        @mouseover="handleMouseenter"
        @mouseleave="handleMouseleave"
      >
        <data-field-component :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import DataFieldComponent from "@/components/data/DataFieldComponent.vue";
import axios from "axios";
export default {
  components: {
    DataFieldComponent,
  },
  data() {
    return {
      headings: ["Parameter", "Tag", "Woche", "Monat", "Jahr", "Seit Beginn"],
      prop: [
        "Temperatur",
        "Regenrate",
        "Luftdruck",
        "Luftfeuchtigkeit",
        "Windgeschwindigkeit",
        "Sonneneinstrahlung",
        "UV-Index",
      ],
      timeSpan: [],
      day: [],
      week: [],
      month: [],
      year: [],
      allTime: [],
    };
  },
  created() {
    const requests = ["day", "week", "month", "year", "allTime"];
    this.timeSpan = requests;
    requests.forEach((request) => {
      axios
        .get(`/statistics/cached?value=${request}`)
        .then(({ data }) => {
          if (data) {
            const dataArray = [];
            dataArray.push({ data: data.temp, unit: "˚C" });
            dataArray.push({ data: data.rain, unit: "mm/h" });
            dataArray.push({ data: data.baro, unit: "hPa" });
            dataArray.push({ data: data.humidity, unit: "%" });
            dataArray.push({ data: data.wind, unit: "km/h" });
            dataArray.push({ data: data.sun.rad, unit: "W/qm" });
            dataArray.push({ data: data.sun.uv, unit: "" });

            this[request] = dataArray;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
  },
  methods: {
    handleMouseenter(e) {
      const element = e.target;
      const siblings = [...document.querySelectorAll(`.${element.classList[1]}`)];
      const allDataElements = [...document.querySelectorAll(".data-field-wrapper")];

      const index = siblings.indexOf(element);

      allDataElements.forEach((el) => {
        el.style.opacity = 0.3;
      });

      allDataElements.forEach((element) => {
        if ((allDataElements.indexOf(element) + 1) % 7 === index) {
          element.style.transform = "scale(1.1)";
          element.style.opacity = 1;
        }
      });

      siblings.forEach((sibling) => {
        sibling.style.opacity = 1;
        sibling.style.transform = "scale(1.1)";
        element.classList.add("highlight");
      });
    },
    handleMouseleave(e) {
      const element = e.target;
      const siblings = [...document.querySelectorAll(`.${element.classList[1]}`)];
      const allDataElements = [...document.querySelectorAll(".data-field-wrapper")];

      // const index = siblings.indexOf(element);

      allDataElements.forEach((el) => {
        el.style.opacity = 1;
      });

      allDataElements.forEach((element) => {
        // if ((allDataElements.indexOf(element) + 1) % 7 === index) {
        element.style.transform = "scale(1)";
        element.style.opacity = 1;
        element.classList.remove("highlight");
        // }
      });

      siblings.forEach((sibling) => {
        sibling.style.transform = "scale(1.0)";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.full-statistics-wrapper {
  margin-top: 80px;
  max-width: calc(100vw - 16px);
  overflow: scroll;
  @media screen and (min-width: $desktopSize) {
    overflow: hidden;
  }
  h2 {
    text-align: center;
  }
}
.data-container {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-template-rows: repeat(7, auto);
  grid-auto-flow: column;
  grid-gap: 16px;
}
.data-field-wrapper {
  transition: all 0.2s;
}
.heading {
  padding: 8px;
}
.prop {
  grid-column: 1/2;
  padding: 8px;
}
.day {
  grid-column: 2/3;
}
.week {
  grid-column: 3/4;
}
.month {
  grid-column: 4/5;
}
.year {
  grid-column: 5/6;
}
.allTime {
  grid-column: 6/7;
}
.lightest-gray {
  background: $lightest-gray;
}
.highlight {
  background: $hs-blue-primary;
  color: $white;
}
</style>
