<template>
  <div class="grid-container">
    <div v-for="(image, index) in imgUrls" :key="index" class="img-container" @click="toggleImage(index)">
      <img :src="`${image?.path && backendURL + image.path}`" />
      <div class="hover-tex">
        <p>Vollansicht</p>
        <div>{{ new Date(image?.ts).toLocaleDateString("de-DE") }}</div>
        <div>{{ new Date(image?.ts).toLocaleTimeString("de-DE") }}</div>
      </div>
    </div>
  </div>
  <div class="container">
    <div v-if="overlayImgOpen" class="overlay-img">
      <p>
        {{ new Date(imgUrls[overlayImg]?.ts).toLocaleDateString("de-DE") }},
        {{ new Date(imgUrls[overlayImg]?.ts).toLocaleTimeString("de-DE") }}
      </p>
      <div @click="toggleImage(0, false)" class="mdi mdi-close"></div>
      <img :src="`${imgUrls[overlayImg]?.path && backendURL + imgUrls[overlayImg]?.path}`" />
      <div class="l-r-buttons">
        <div
          @click="toggleImage(overlayImg - 1)"
          class="mdi mdi-arrow-left-drop-circle"
          :style="overlayImg === 0 ? 'opacity: .5; cursor: auto' : ''"
        ></div>
        <div
          @click="toggleImage(overlayImg + 1)"
          class="mdi mdi-arrow-right-drop-circle"
          :style="overlayImg === imgUrls.length - 1 ? 'opacity: .5; cursor: auto' : ''"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: Array,
  },
  data() {
    return {
      imgUrls: this.images,
      overlayImg: 0,
      overlayImgOpen: false,
      backendURL: process.env.VUE_APP_BACKEND_URL
    };
  },
  methods: {
    toggleImage(index, open = true) {
      if (index >= 0 && index < this.imgUrls.length) {
        this.overlayImg = index;
        this.overlayImgOpen = open;
      }
    },
  },
  watch: {
    images: function (newVal) {

      this.imgUrls = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media screen and (min-width: $tabletSize) {
    grid-template-columns: repeat(5, 1fr);
  }
  grid-gap: 8px;
  @media screen and (min-width: $desktopSize) {
    grid-gap: 16px;
  }
  .img-container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: calc((100vw - 2 * 8px - 2 * 16px) / 3);
    @media screen and (min-width: $tabletSize) {
      height: calc((100vw - 4 * 8px - 2 * 16px) / 5);
    }
    @media screen and (min-width: $desktopSize) {
      height: calc((1024px - 4 * 16px - 2 * 16px) / 5);
    }
    &:hover .hover-tex {
      display: block;
    }
    .hover-tex {
      pointer-events: none;
      position: absolute;
      display: none;
      text-align: center;
    }
    img {
      height: 100%;
      &:hover {
        opacity: 0.3;
      }
    }
  }
}
.container {
  margin-bottom: 80px;
}
.overlay-img {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: $headerNavHeightMobile;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: $dark-gray-opacity;
  img {
    width: 100%;
    @media screen and (min-width: $desktopSize) {
      width: 1024px - 32px;
      margin: 0 auto;
    }
  }
  p {
    color: $white;
    text-align: center;
    font-size: 16px;
  }
}
.mdi {
  align-self: flex-end;
  color: $white;
  cursor: pointer;
}
.mdi::before {
  font-size: 40px;
}
.mdi-close {
  @media screen and (min-width: $desktopSize) {
    width: 1024px - 32px;
    margin: 0 auto;
    text-align: right;
    padding-bottom: 16px;
  }
}
.l-r-buttons {
  display: flex;
  justify-content: space-around;
  padding: 24px;
  @media screen and (min-width: $desktopSize) {
    width: 50%;
    margin: 0 auto;
  }
}
</style>
