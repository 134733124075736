<template>
 <impressum-component />
</template>
<script>
import ImpressumComponent from '@/components/legal/ImpressumComponent'
export default {
  components: {
    ImpressumComponent
  }
}
</script>