<template>
  <div>
    <home-component class="section" id="wetter" />
    <project-component class="section" id="projekt" />
    <gallery-component class="section" id="galerie" />
    <data-component class="section" id="daten" />
    <team-component class="section" id="team" />
  </div>
</template>

<script>
import HomeComponent from "@/components/home/HomeComponent";
import ProjectComponent from "@/components/project/ProjectComponent";
import GalleryComponent from "@/components/gallery/GalleryComponent";
import DataComponent from "@/components/data/DataComponent";
import TeamComponent from "@/components/team/TeamComponent";

export default {
  components: {
    HomeComponent,
    ProjectComponent,
    GalleryComponent,
    DataComponent,
    TeamComponent,
  },
};
</script>

<style lang="scss" scoped>
.section {
  margin-bottom: 120px;
}
</style>
