<template>
  <div class="teamContainer">
    <h2 class="teamHeader">Das Team</h2>
    <div class="profText">
      <h3>Professoren / Mitarbeitende der Hochschule Worms</h3>
      <h3>Beteiligt am Projekt ab 2020</h3>
      Prof. Dr. Herbert Thielen (Projektverantwortlicher)
      <h3>Ehemalige Beteiligte</h3>
      <ul>
        <li>Prof. Dr. St. Zimmermann (Projektinitiator, Projektverantwortlicher)</li>
        <li>Herr Schumacher (technische Leitung)</li>
        <li>Herr Damm</li>
      </ul>
    </div>
    <div class="img-container">
      <img v-if="isMobile" src="@/assets/zeitstrahl-vertikal.svg" alt="team-zeitstrahl" />
      <img v-if="!isMobile" src="@/assets/zeitstrahl-horizontal.svg" alt="team-zeitstrahl" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: true,
    };
  },

  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      const windowWidth = window.innerWidth;
      this.isMobile = windowWidth >= 768 ? false : true;
    },
  },
};
</script>

<style lang="scss" scoped>
.teamContainer {
  display: grid;
  @media screen and (min-width: $tabletSize) {
    grid-template-columns: 300px 1fr;
  }
}
.teamHeader {
  font-size: 36px;
  align-self: center;
  justify-self: center;
}
.img-container {
  margin-top: 80px;
  @media screen and (min-width: $tabletSize) {
    grid-column: 1/3;
  }
}
img {
  @media screen and (min-width: $desktopSize) {
    width: 1024px;
  }

  width: 100%;
}
h2,
h3 {
  font-weight: normal;
}
h3 {
  margin-top: 32px;
  font-size: 18px;
  text-align: left;
}
ul {
  padding: 0;
  list-style-type: none;
}
li {
  margin: 16px 0;
}
</style>
