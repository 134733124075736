<template>
  <div id="moewenContainer">
    <Markdown class="markdown" :source="content" />
    <div class="loading-component-wrapper">
      <loading-component v-if="!content" :message="loadingMessage" />
    </div>

    <div>
      <h4>Mit freundlicher Unterstützung von</h4>
      <a href="https://www.nabu-worms.de/" target="_blank" rel="noopener">
        <img
          class="logo"
          src="https://image.jimcdn.com/app/cms/image/transf/dimension=1920x1024:format=jpg/path/sbbb25f262b27a0a9/image/iead31bfd61e77ba8/version/1513589767/image.jpg"
          alt="NABU"
        />
      </a>
    </div>
  </div>
</template>

<script>
import Markdown from "vue3-markdown-it";
import LoadingComponent from "@/components/miscellaneous/LoadingComponent.vue";
import axios from "axios";
export default {
  components: {
    Markdown,
    LoadingComponent,
  },
  data() {
    return {
      content: "",
      loadingMessage: "Möwen Informationen werden geladen.",
    };
  },
  methods: {},
  created() {
    axios
      .get("/getGullText")
      .then((response) => {
        if (response.status === 200) {
          this.content = response.data.content;
        } else {
          console.warn("/getGullText Response status " + response.status);
        }
      })
      .catch((error) => {
        console.error("/getGullText Request failed with Error" + error);
      });
    this.loadingMessage =
      "Möwen Informationen konnten nicht geladen werden. \n Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.";
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
#moewenContainer {
  @media screen and (min-width: $desktopSize) {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 16px;
  }
}
.loading-component-wrapper {
  grid-column: 1/13;
}
h4 {
  margin: auto;
  display: block;
  text-align: center;
  font-size: medium;
}
img {
  width: 100%;
  max-width: 480px;
  margin: auto;
  display: block;
}
</style>
