<template>
  <div class="loading-container">
    <img src="@/assets/weather-sunny.svg" class="spinner" />
    <p>{{ this.message }}</p>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
  },
};
</script>

<style lang="scss" scoped>
.loading-container {
  color: $hs-blue-primary;
  align-self: center;
  text-align: center;
}
p {
  white-space: pre-wrap;
}
.spinner {
  width: 20%;
  max-width: 120px;
  animation: rotate-spinner 1.2s ease-in-out infinite;
}
@keyframes rotate-spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
