<template>
  <img :src="link" alt="Stream Wetterkamera" />
</template>

<script>
export default {
  props: {
    link: String,
  },
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100vw;
  margin: 0 -16px;
  @media screen and (min-width: $desktopSize) {
    max-width: 100%;
    margin: 0;
  }
}
</style>
