<template>
  <div class="container">
    <tab-nav-component :navigation="navigation" />
    <keep-alive>
      <about-component v-if="$route.query.projekt === 'ueber-uns' || $route.query.projekt === undefined" />
    </keep-alive>
    <keep-alive>
      <gulls-component v-if="$route.query.projekt === 'moewen'" />
    </keep-alive>
    <keep-alive>
      <news-component v-if="$route.query.projekt === 'neuigkeiten'" />
    </keep-alive>
  </div>
</template>

<script>
import TabNavComponent from "@/components/layout/TabNavComponent";
import AboutComponent from "@/components/project/AboutComponent";
import GullsComponent from "@/components/project/GullsComponent";
import NewsComponent from "@/components/project/NewsComponent";
export default {
  components: { TabNavComponent, AboutComponent, GullsComponent, NewsComponent },
  data() {
    return {
      navigation: [
        { name: "projekt", param: "ueber-uns", linkText: "Über uns" },
        { name: "projekt", param: "moewen", linkText: "Die Möwen" },
        { name: "projekt", param: "neuigkeiten", linkText: "Neuigkeiten" },
      ],
    };
  },
};
</script>