<template>
  <div>
    <div class="container">
      <div class="inputBox selectBox selectBox1" :class="this.settings.selection1 == '' ? 'wrongBox' : ''">
        <span class="inputTitle">Wert 1</span>
        <select class="input" :id="'select1'" v-model="settings.selection1">
          <option value disabled>Bitte wählen Sie einen Datentyp</option>
          <option v-for="(option, prop, index) in dataTypes" :key="index" :value="prop">
            {{ option.label }}
          </option>
        </select>
        <hr class="selectionLine" />
      </div>
      <div class="inputBox selectBox selectBox2">
        <span class="inputTitle">Wert 2</span>
        <select class="input" :id="'select2'" v-model="settings.selection2">
          <option value>Optionaler Datentyp</option>
          <option v-for="(option, prop, index) in dataTypes" :key="index" :value="prop">
            {{ option.label }}
          </option>
        </select>
        <hr class="selectionLine" />
      </div>

      <div class="inputBox dateBox dateBox1" :class="this.wrongStartDate ? 'wrongBox' : ''">
        <span class="inputTitle">Anfangsdatum</span>
        <input class="input" ref="picker1" v-model="settings.from" />
        <hr class="selectionLine" />
      </div>

      <div class="inputBox dateBox dateBox2" :class="this.wrongEndDate ? 'wrongBox' : ''">
        <span class="inputTitle">Enddatum</span>
        <input class="input" ref="picker2" v-model="settings.to" />
        <hr class="selectionLine" />
      </div>

      <button class="submit-button" :disabled="disableSubmit" :id="'go'" @click="requestNewChart()">
        <span>Abrufen</span>
      </button>
    </div>
    <apexchart width="100%" height="300px" :options="chartOptions" :series="series" :key="testKey"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import axios from "axios";
const { DateTime } = require("luxon");
let de = require("apexcharts/dist/locales/de.json");

import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js";
require("flatpickr/dist/themes/light.css");
flatpickr.localize(German);

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    data: Object,
    id: Number,
  },
  data: function () {
    return {
      testKey: 1, //testKey is just a quick bugfix, further info in the wiki
      chartOptions: {
        chart: {
          locales: [de],
          defaultLocale: "de",
        },
        noData: {
          text: "",
          style: {
            fontSize: "20pt",
          },
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: [],
        tooltip: {
          x: {
            format: "dd.MM.yyyy HH:mm",
          },
          y: {
            formatter: function (value, { seriesIndex, w }) {
              return value + " " + w.globals.lastYAxis[seriesIndex].label;
            },
          },
        },
      },
      series: [],
      dataTypes: {
        tempOut: {
          label: "Temperatur",
          unit: "°C",
          fillBottom: "#0000FF",
          fillTop: "#FF0000",
        },
        tempOutMa: {
          label: "Temperatur (gleitender Jahresmittelwert)",
          unit: "°C",
          fillBottom: "#ff9b00",
          fillTop: "#ff9b00",
        },
        uv: {
          label: "UV Index",
          unit: "",
          fillBottom: "#FFFF00",
          fillTop: "#FFFF00",
        },
        humOut: {
          label: "Luftfeuchtigkeit",
          unit: "%",
          fillBottom: "#385aff",
          fillTop: "#0b1e7a",
        },
        rainRate: {
          label: "Regenrate",
          unit: "mm/h",
          fillBottom: "#a6b8ff",
          fillTop: "#000366",
        },
        rainDay: {
          label: "Tägliche Regenmenge",
          unit: "mm",
          fillBottom: "#a6b8ff",
          fillTop: "#000366",
        },
        etDay: {
          label: "Tägliche Evapotranspiration",
          unit: "mm",
          fillBottom: "#000366",
          fillTop: "#a6b8ff",
        },
        baro: {
          label: "Luftdruck",
          unit: "hPa",
          fillBottom: "#d3bdff",
          fillTop: "#3e2275",
        },
        rad: {
          label: "Sonneneinstrahlung",
          unit: "W/qm",
          fillBottom: "#e8df2e",
          fillTop: "#a32103",
        },
        radMa: {
          label: "Sonneneinstrahlung (gleitender Jahresmittelwert)",
          unit: "W/qm",
          fillBottom: "#C68019",
          fillTop: "#C68019",
        },
        windAvg: {
          label: "Windgeschwindigkeit",
          unit: "km/h",
          fillBottom: "#e3b671",
          fillTop: "#e68a00",
        },
        windSpeedMa: {
          label: "Windgeschwindigkeit (gleitender Jahresmittelwert)",
          unit: "km/h",
          fillBottom: "#e4b030",
          fillTop: "#e4b030",
        },
      },
      settings: {
        selection1: "",
        selection2: "",
        from: DateTime.now().startOf("day").plus({ days: -1 }).toFormat("yyyy-MM-dd HH:mm:ss"),
        to: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
        data: {},
        dataSelection1: "",
        dataSelection2: "",
        dataFrom: "",
        dataTo: "",
        loading: false,
      },
    };
  },
  computed: {
    disableSubmit() {
      return this.settings.loading || this.wrongStartDate || this.wrongEndDate || this.settings.selection1 == "";
    },
    wrongStartDate() {
      return this.settings.from == "" || this.endBeforeStartDate;
    },
    wrongEndDate() {
      return this.settings.to == "" || this.endBeforeStartDate;
    },
    endBeforeStartDate() {
      return this.settings.from > this.settings.to;
    },
    gotNewData() {
      let c = this.settings;
      return (
        c.from != c.dataFrom || c.to != c.dataTo || c.selection1 != c.dataSelection1 || c.selection2 != c.dataSelection2
      );
    },
  },
  methods: {
    requestNewChart() {
      let c = this.settings;
      this.chartOptions = { ...this.chartOptions, ...{ noData: { text: "Daten werden geladen..." } } };

      // if inputs changed
      if (this.gotNewData) {
        c.data = {};

        c.dataFrom = c.from;
        c.dataTo = c.to;
        c.dataSelection1 = c.selection1;
        c.dataSelection2 = c.selection2;

        this.series = [];
        this.yaxis = [];
        c.loading = true;

        axios
          .get("/graph", {
            params: {
              from: c.from,
              to: c.to,
              value: c.selection1 + (c.selection1 != "" && c.selection2 != "" ? "," : "") + c.selection2,
            },
          })
          .then(({ data }) => {
            c.data = data;
            if (Object.keys(data).length > 0) {
              const type1 = this.mapSerie(0);

              // set min and max value
              const maxValue = c.data[Object.keys(c.data)[0]].reduce((p, v) => (p.y > v.y ? p : v));
              const minValue = c.data[Object.keys(c.data)[0]].reduce((p, v) => (p.y < v.y ? p : v));

              const valuePadding = Math.ceil((maxValue.y - minValue.y) / 10) || 1;

              const axisMin = +minValue.y - valuePadding;
              const axisMax = +maxValue.y + valuePadding;

              let newYaxis = [
                {
                  label: type1.unit,
                  decimalsInFloat: 0,
                  min: axisMin,
                  max: axisMax,
                  forceNiceScale: true,
                  title: {
                    //ternary needed because UV-Index has no unit
                    text: type1.unit ? type1.label + " in " + type1.unit : type1.label,
                  },
                },
              ];

              let colorStops = [
                [
                  {
                    offset: 0,
                    color: type1.fillTop,
                    opacity: 1,
                  },
                  {
                    offset: 100,
                    color: type1.fillBottom,
                    opacity: 1,
                  },
                ],
              ];

              // if second option is selected
              if (Object.keys(c.data).length > 1) {
                const type2 = this.mapSerie(1);

                const maxValue2 = c.data[Object.keys(c.data)[1]].reduce((p, v) => (p.y > v.y ? p : v));
                const minValue2 = c.data[Object.keys(c.data)[1]].reduce((p, v) => (p.y < v.y ? p : v));
                const valuePadding2 = Math.ceil((maxValue2.y - minValue2.y) / 10) || 1;

                const axisMin2 = +minValue2.y - valuePadding2;
                const axisMax2 = +maxValue2.y + valuePadding2;

                if (type1.unit !== type2.unit) {
                  //use different y axis
                  newYaxis.push({
                    label: type2.unit,
                    decimalsInFloat: 0,
                    opposite: true,
                    min: axisMin2,
                    max: axisMax2,
                    forceNiceScale: true,
                    title: {
                      text: type2.unit ? type2.label + " in " + type2.unit : type2.label,
                    },
                  });
                } else {
                  newYaxis[0].min = Math.min(axisMin, axisMin2);
                  newYaxis[0].max = Math.max(axisMax, axisMax2);
                }
                colorStops.push([
                  {
                    offset: 0,
                    color: type2.fillTop,
                    opacity: 1,
                  },
                  {
                    offset: 100,
                    color: type2.fillBottom,
                    opacity: 1,
                  },
                ]);
              }

              this.chartOptions = {
                ...this.chartOptions,
                ...{
                  fill: {
                    type: "gradient",
                    gradient: {
                      type: "vertical",
                      shadeIntensity: 1,
                      opacityFrom: 0.7,
                      opacityTo: 0.9,
                      stops: [],
                      colorStops: colorStops,
                    },
                  },
                },
              };

              this.chartOptions = { ...this.chartOptions, ...{ yaxis: newYaxis } };
            }

            c.loading = false;
            this.chartOptions = { ...this.chartOptions, ...{ noData: { text: "" } } };
          })
          .catch(() => {
            c.loading = false;
            this.chartOptions = { ...this.chartOptions, ...{ noData: { text: "" } } };
          });
      }
    },
    mapSerie(keyIndex) {
      // add data from series to graph
      const key = Object.keys(this.settings.data)[keyIndex];
      let keyType = null;
      if (Object.keys(this.dataTypes).includes(key)) {
        keyType = this.dataTypes[key];

        let serie = {
          type: "line",
          name: keyType.label,
          data: this.settings.data[key],
        };
        this.series.push(serie);
        return keyType;
      } else {
        this.chartOptions = { ...this.chartOptions, ...{ noData: { text: "There was an error retrieving data." } } };
      }
    },
  },
  mounted() {
    if (this.id === 0) {
      this.settings.selection1 = "tempOut";
      this.requestNewChart();
      this.testKey++; //testKey is just a quick bugfix, further info in the wiki
    }

    flatpickr(this.$refs.picker1, {
      disableMobile: "true",
      enableTime: true,
      altInput: true,
      altFormat: "d.m.Y H:i",
      defaultHour: 10,
      enable: [
        {
          from: "2007-07-29T00:00",
          to: new Date().toISOString(),
        },
      ],
    });

    flatpickr(this.$refs.picker2, {
      disableMobile: "true",
      enableTime: true,
      altInput: true,
      altFormat: "d.m.Y H:i",
      enable: [
        {
          from: "2007-07-29T00:00",
          to: new Date().toISOString(),
        },
      ],
    });
    console.log(new Date("2022-04-11T22:00:00.000Z"));
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  margin-bottom: 80px;
  width: 100%;
  row-gap: 18px;
  column-gap: 8px;
  grid-template-columns: repeat(12, [col-start] 1fr);

  @media screen and (min-width: $tabletSize) {
    column-gap: 16px;
  }
}
.input {
  border: 0;
  background-color: $lightest-gray;
  font-size: 11px;
  width: 100%;
}
.inputBox {
  height: 64px;
  padding: 8px;
  box-sizing: border-box;
  background-color: $lightest-gray;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  border-radius: 8px 8px 0px 0px;
}
.inputTitle {
  font-size: 12px;
  margin-bottom: 8px;
  display: block;
}
.selectBox {
  font-size: 11px;
  display: block;
  border-width: 0;
  background-color: $lightest-gray;
}
.selectBox1 {
  grid-area: 1/1/2/13;
  @media screen and (min-width: $tabletSize) {
    grid-area: 1/1/2/7;
  }
  @media screen and (min-width: $desktopSize) {
    grid-area: 1/1/2/3;
  }
}
.selectBox2 {
  grid-area: 2/1/3/13;
  @media screen and (min-width: $tabletSize) {
    grid-area: 1/7/2/13;
  }
  @media screen and (min-width: $desktopSize) {
    grid-area: 1/3/2/5;
  }
}
.dateBox1 {
  grid-area: 3/1/4/7;
  @media screen and (min-width: $tabletSize) {
    grid-area: 2/3/3/6;
  }
  @media screen and (min-width: $desktopSize) {
    grid-area: 1/5/2/8;
  }
}
.dateBox2 {
  grid-area: 3/7/4/13;
  @media screen and (min-width: $tabletSize) {
    grid-area: 2/6/3/9;
  }
  @media screen and (min-width: $desktopSize) {
    grid-area: 1/8/2/11;
  }
}
.selectionLine {
  height: 1.5px;
  margin-bottom: 4px;
  border-width: 0;
  color: gray;
  background-color: gray;
}
.submit-button {
  grid-area: 4/1/5/13;
  @media screen and (min-width: $tabletSize) {
    grid-area: 2/9/3/11;
  }
  @media screen and (min-width: $desktopSize) {
    grid-area: 1/11/2/13;
  }
}
.wrongBox {
  border: 1px solid red;
}
</style>
