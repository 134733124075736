import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueSocketIO from "vue-3-socket.io";
import io from "socket.io-client";
import store from "./store";

// set backend urls for all axios requests
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL + "/api/v3";

//Websockets
const ioSocket = io(process.env.VUE_APP_BACKEND_URL);

createApp(App)
  .use(router)
  .use(
    new VueSocketIO({
      debug: process.env.VUE_APP_DEBUG == "true",
      connection: ioSocket,
      withCredentials: true,
      vuex: {
        store,
        actionPrefix: "SOCKET_",
        mutationPrefix: "SOCKET_",
      },
    })
  )
  .mount("#app");
