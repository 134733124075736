<template>
  <div>
    <h2>Daten</h2>
    <tab-nav-component :navigation="navigation" />
    <keep-alive>
      <graphs-component v-if="$route.query.daten === 'graphen' || $route.query.daten === undefined" />
    </keep-alive>
    <keep-alive>
      <statistics-component v-if="$route.query.daten === 'statistiken'" />
    </keep-alive>
  </div>
</template>

<script>
import TabNavComponent from "@/components/layout/TabNavComponent";
import GraphsComponent from "@/components/data/GraphsComponent";
import StatisticsComponent from "@/components/data/StatisticsComponent";
export default {
  components: {
    TabNavComponent,
    GraphsComponent,
    StatisticsComponent,
  },
  data() {
    return {
      navigation: [
        { name: "daten", param: "graphen", linkText: "Graphen" },
        { name: "daten", param: "statistiken", linkText: "Statistiken" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: center;
  font-weight: lighter;
  font-size: xxx-large;
}
</style>
