<template>
  <div class="container" :style="'background-color:' + this.data.background">
    <Markdown id="banner_box" :style="' color:' + this.data.color" :source="this.data.content" />
    <button id="close_button" aria-label="delete item" @click="$emit('deleteFloat')" type="button">
      <em :class="'mdi mdi-close'"></em>
    </button>
  </div>
</template>

<script>
import Markdown from "vue3-markdown-it";
export default {
  components: {
    Markdown,
  },
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  bottom: 32px;
  right: 32px;
  padding: 8px;
  width: calc(100vw - 64px);
  border-radius: 16px;
  display: grid;
  grid-template-columns: auto 24px;
  grid-auto-rows: 1fr;
  column-gap: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  @media screen and (min-width: $tabletSize) {
    width: 400px;
  }
}
#banner_box {
  grid-column: 1 / span 1;
  margin: 2px;
  padding: 8px;
}
#banner_box ::v-deep(h2) {
  font-size: medium;
  margin: 0;
  font-weight: regular;
}
#close_button {
  grid-column: 2 / span 1;
  margin: 8px 24px 0 0;
  align-self: start;
  border: none;
  background-color: transparent;
}
</style>
