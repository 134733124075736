<template>
  <div class="footer-wrapper" :style="`position: ${mobileNavOpen ? 'fixed' : 'absolute'}`">
    <footer>
      <router-link to="/impressum">Impressum</router-link>
      <router-link to="/datenschutz">Datenschutz</router-link>
      <a href="/api/doc" target="_blank">Rohdaten API</a>
      <div class="userstats">
        <span> {{ todayUsers }} Benutzer heute</span>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    mobileNavOpen: Boolean,
  },
  data() {
    return {
      todayUsers: 0,
    };
  },
  sockets: {
    userCount: function (value) {
      this.todayUsers = value;
    },
  },
  created() {
    axios.get("/todayUsers").then(({ data }) => {
      this.todayUsers = data.count;
    });
  },
};
</script>

<style lang="scss" scoped>
.footer-wrapper {
  background-color: $hs-blue-primary;
  left: 0;
  right: 0;
  bottom: -1px;
  height: $footerHeightMobile;
  padding: 24px;
  display: grid;
  @media screen and (min-width: $tabletSize) {
    height: $footerHeightTabletUp;
    padding: 24px calc((100vw - 1024px) / 2);
  }
}
footer {
  display: grid;
  grid-gap: 24px;
  @media screen and (min-width: $tabletSize) {
    grid-template-columns: repeat(4, auto);
    text-align: center;
    align-items: center;
  }
}
a,
span {
  color: $white;
}
</style>
