<template>
  <div class="main-wrapper">
    <header-nav-mobile-component v-if="mobileView" />
    <header-nav-tablet-up-component v-else />
    <router-view></router-view>
  </div>
  <footer-component :mobileNavOpen="false" />
  <toast-component v-if="bannerData.show" :data="bannerData" @delete-Float="hideFloat" />
</template>

<script>
import FooterComponent from "@/components/layout/FooterComponent";
import HeaderNavMobileComponent from "@/components/layout/HeaderNavMobileComponent";
import HeaderNavTabletUpComponent from "@/components/layout/HeaderNavTabletUpComponent";
import ToastComponent from "@/components/miscellaneous/ToastComponent";
import axios from "axios";

export default {
  components: {
    FooterComponent,
    HeaderNavMobileComponent,
    HeaderNavTabletUpComponent,
    ToastComponent,
  },
  data() {
    return {
      mobileView: true,
      bannerData: {},
    };
  },
  created() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    axios.get("/getBanner").then(({ data }) => {
      this.bannerData = data;
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.mobileView = window.innerWidth < 768;
    },
    hideFloat() {
      this.bannerData.show = false;
    },
  },
};
</script>

<style lang="scss">
$mdi-font-path: "../node_modules/@mdi/font/fonts";
@import "@mdi/font/scss/materialdesignicons.scss";
* {
  box-sizing: border-box;
}
html,
body {
  font-family: $body-font-family;
  font-size: $font-size-root;
  margin: 0;
  position: relative;
}
#app {
  max-width: 1024px;
  margin: auto;
  min-height: 100vh;
  padding: calc($headerNavHeightMobile + 40px) 16px calc($footerHeightMobile);
  scroll-behavior: smooth;
  @media screen and (min-width: $tabletSize) {
    padding: calc($headerNavHeightMobile + 40px) 16px calc($footerHeightTabletUp);
  }
}
.main-wrapper {
  position: relative;
}
</style>
