<template>
  <div class="container">
    <div class="img-container">
      <h3 v-if="images.length === 0">Aktuell keine Bilder gefunden</h3>
      <div v-if="activeImg !== 0 && images.length !== 0" @click="changeActiveImg(activeImg - 1)" class="mdi mdi-arrow-left-drop-circle"></div>
      <img
        :src="`${images[activeImg]?.path && backendURL + images[activeImg].path}`"
        alt=""
      />
      <div
        v-if="activeImg !== images.length - 1 && images.length !== 0"
        @click="changeActiveImg(activeImg + 1)"
        class="mdi mdi-arrow-right-drop-circle"
      ></div>
    </div>
    <p v-if="images.length">
      {{ new Date(images[activeImg]?.ts).toLocaleDateString("de-DE") }},
      {{ new Date(images[activeImg]?.ts).toLocaleTimeString("de-DE") }}
    </p>
    <div class="selection-container">
      <div class="selection">
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image.path && backendURL + image.path"
          :class="activeImg === index ? `highlighted-img` : ``"
          class="selection-img"
          @click="changeActiveImg(index)"
        />
      </div>
    </div>
    <loading-component v-if="loading" :message="loadingMessage" />
  </div>
</template>

<script>
import axios from "axios";
import LoadingComponent from "@/components/miscellaneous/LoadingComponent";
export default {
  components: {
    LoadingComponent,
  },
  data() {
    return {
      images: [],
      activeImg: 0,
      loading: true,
      backendURL: process.env.VUE_APP_BACKEND_URL
    };
  },
  methods: {
    changeActiveImg(newIndex) {
      if (newIndex >= 0 && newIndex < this.images.length) {
        this.activeImg = newIndex;
      }
    },
  },
  created() {
    axios
      .get("/images/day")
      .then(({ data }) => {
        if (data) {
          this.images = data.data.reverse();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.error(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 64px;
}
.img-container {
  position: relative;
  display: flex;
  align-items: center;
  img {
    max-width: 100vw;
    margin-left: -16px;
    @media screen and (min-width: $desktopSize) {
      max-width: 800px;
      margin: auto;
    }
  }
}
h3 {
  text-align: center;
  width: 100%;
}
.mdi-arrow-left-drop-circle {
  position: absolute;
  left: 0;
  @media screen and (min-width: $desktopSize) {
    left: 16px;
  }
}
.mdi-arrow-right-drop-circle {
  position: absolute;
  right: 0;
  @media screen and (min-width: $desktopSize) {
    right: 16px;
  }
}
.mdi::before {
  font-size: 40px;
  color: $white;
  @media screen and (min-width: $desktopSize) {
    color: $hs-blue-primary;
  }
}
p {
  text-align: center;
}
.selection-container {
  // hiding scrollbar cross browser
  position: relative;
  overflow: hidden;
  height: 80px;
  margin: auto;
  max-width: 425px;
  img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
}
.selection {
  // hiding scrollbar cross browser
  overflow-x: scroll;
  overflow-y: hidden;
  position: absolute;
  left: 0px;
  right: -17px;
  bottom: -17px;
  max-width: calc(100vw - 40px);
  display: flex;
  padding: 16px 0;
}
.highlighted-img {
  transform: scale(0.9);
  opacity: 0.5;
}
</style>
