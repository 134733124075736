<template>
  <loading-component v-if="loading" message="" />
  <h3 v-if="!loading && !images.length">Keine Bilder gefunden</h3>
  <tile-gallery-component :images="images" />
</template>

<script>
import TileGalleryComponent from "@/components/gallery/TileGalleryComponent";
import LoadingComponent from "@/components/miscellaneous/LoadingComponent";
import axios from "axios";
export default {
  components: { TileGalleryComponent, LoadingComponent },
  data() {
    return {
      loading: true,
      images: [],
    };
  },
  created() {
    axios
      .get("/images/interesting")
      .then(({ data }) => {
        if (data) {
          this.images = data.data.reverse();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.error(error);
      });
  },
};
</script>
