<template>
  <div>
    <Markdown v-if="content" class="markdown" :source="content" />
    <loading-component v-if="!content" :message="loadingMessage" />
  </div>
</template>

<script>
import Markdown from "vue3-markdown-it";
import axios from "axios";
import LoadingComponent from "@/components/miscellaneous/LoadingComponent.vue";

export default {
  components: {
    Markdown,
    LoadingComponent,
  },
  data() {
    return {
      content: "",
      loadingMessage: "Neuigkeiten werden geladen.",
    };
  },
  methods: {},
  created() {
    axios
      .get("/getNews")
      .then((response) => {
        if (response.status === 200) {
          this.content = response.data.content;
        } else {
          console.warn("/getNews Response status " + response.status);
        }
      })
      .catch((error) => {
        console.error("/getNews Request failed with Error" + error);
      });
    this.loadingMessage =
      "Neuigkeiten konnten nicht geladen werden. \n Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.";
  },
  mounted() {},
};
</script>
