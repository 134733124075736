<template>
  <img
    src="@/assets/compass_needle.svg"
    alt="Kompass Rose"
    class="topRight needle"
    :style="'transform: perspective(400px) rotateX(60deg) '"
  />
  <div class="clipCompass topRight">
    <img
      src="@/assets/compass_rose.svg"
      alt="Kompass Nadel"
      class="compass"
      :style="'transform: perspective(600px) rotateX(50deg) rotate(' + (360 - this.rotation) + 'deg)'"
    />
  </div>
</template>
<script>
export default {
  props: {
    rotation: Number,
  },
  data() {
    return {
      compassRotation: this.rotation
    }
  },
 watch: {
    rotation: function () {
      this.compassRotation = this.rotation;
    },
  },
};
</script>
<style lang="scss" scoped>
.clipCompass {
  clip-path: inset(0 0 50% 0);
  mask-image: radial-gradient(at top, black 30%, transparent 50%);
}
.compass {
  width: 100%;
}
.topRight {
  position: absolute;
  top: -16px;
  right: 16px;
  width: 140px;
  @media screen and (min-width: $tabletSize) {
    width: 25%;
  }
}
</style>
