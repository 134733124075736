<template>
  <div class="container">
    <div v-if="!item.data">Keine Daten vorhanden</div>
    <div v-else>
      <div class="data" v-if="item.data.avg">Schnitt: {{ item.data.avg.value }} {{ item.unit }}</div>
      <div class="data" v-if="item.data.min">
        <div class="value">Min: {{ item.data.min.value }} {{ item.unit }}</div>
        <div class="dateTime">
          <span class="date">{{ new Date(item.data.min.date).toLocaleDateString("de-DE") }}</span>
          <span class="time"> {{ new Date(item.data.min.date).toLocaleTimeString("de-DE") }}</span>
        </div>
      </div>
      <div class="data">
        <div>Max: {{ item.data.max.value }} {{ item.unit }}</div>
        <div class="dateTime">
          <span class="date">{{ new Date(item.data.max.date).toLocaleDateString("de-DE") }}</span>
          <span class="time"> {{ new Date(item.data.max.date).toLocaleTimeString("de-DE") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>

<style lang="scss" scoped>
.container {
  pointer-events: none;
}
.data {
  padding: 8px;
}
.date {
  margin-right: 8px;
}
</style>
