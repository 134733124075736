<template>
  <div>
    <h1>Datenschutzerklärung</h1>
    <p>
      <strong>Datenschutzerklärung gemäß Artikel 12 der EU Datenschutzgrundverordnung (DSGVO)</strong> der Hochschule
      Worms, Körperschaft öffentlichen Rechts und zugleich staatliche Einrichtung.<br />
      Die Hochschule Worms erhebt und verarbeitet Daten von Besuchern, die auf elektronischem Weg mit der Hochschule in
      Kontakt treten. Verantwortlich für den Datenschutz ist der Präsident, beratend steht ihm der/die
      Datenschutzbeauftragte zur Seite.
    </p>

    <h5>Erreichbarkeit der Verantwortlichen</h5>
    <p>
      Hochschule Worms<br />
      Der Präsident<br />
      Erenburgerstraße 19<br />
      67549 Worms<br /><br />
      T: +49(0)6241.509-248<br />
      F: +49(0)6241.509-222<br />
      E: praesident@hs-worms.de<br /><br />
    </p>
    <p>
      Hochschule Worms<br />
      Datenschutzbeauftragte<br />
      Erenburgerstraße 19<br />
      67549 Worms<br /><br />
      T: +49(0)6241.509-0<br />
      F: +49(0)6241.509-280<br />
      E: datenschutz@hs-worms.de<br /><br />
    </p>
    <p>
      Das Beschwerderecht gemäß Artikel 13 DS-GVO ist bei der für die Hochschule Worms zuständigen Aufsichtsbehörde
      geltend zu machen:<br /><br />
      Der Landesbeauftragte<br />
      für den Datenschutz und die Informationsfreiheit Rheinland-Pfalz LfDI<br />
      Postfach 3040<br />
      55020 Mainz<br /><br />
    </p>
    <p>
      Welche Daten während Ihres Besuchs auf unseren Webseiten erfasst und wie diese verwendet werden, erläutern wir in
      den nächsten Abschnitten.
    </p>
    <ol>
      <li>Datenerhebung und -verarbeitung bei Zugriffen aus dem Internet</li>
      <li>Einsatz von Cookies</li>
      <li>Erhebung und Verarbeitung personenbezogener Daten</li>
      <li>Einwilligung in weitergehende Datennutzung</li>
      <li>Datensicherheit</li>
      <li>Links zu Webseiten anderer Anbieter</li>
      <li>Auskunftsrecht und Kontaktdaten</li>
      <li>Aktualität und Gültigkeit der Datenschutzerklärung</li>
      <li>Rechte für Betroffene gemäß DSGVO</li>
    </ol>
    <br />
    <h4>1. Datenerhebung und -verarbeitung bei Zugriffen aus dem Internet</h4>
    <p>
      Ihr Besuch auf den Web-Diensten des Fachbereichs Informatik wird protokolliert. Erfasst werden im Wesentlichen
      folgende Daten:
    </p>
    <ul>
      <li>die aktuell von Ihrem PC verwendete IP-Adresse</li>
      <li>der Name Ihres Internet-Providers</li>
      <li>Datum und Uhrzeit des Zugriffs</li>
      <li>der Browsertyp und das Betriebssystem Ihres PC</li>
      <li>die von Ihnen betrachteten Seiten</li>
      <li>der Zugriffsstatus (Datei übertragen, Datei nicht gefunden etc.)</li>
      <li>die Erkennungsdaten des verwendeten Browser- und Betriebssystems</li>
      <li>die Webseite, von der aus der Zugriff erfolgte</li>
    </ul>
    <p>
      Die Verarbeitung dieser Daten ermöglicht die Nutzung unserer Webseite (Verbindungsaufbau), dient der
      Systemsicherheit, der technischen Administration der Netzinfrastruktur sowie zur Optimierung des Internetangebots.
      IP-Adressen werden nur bei Angriffen auf die Netzinfrastruktur der Hochschule Worms ausgewertet.
    </p>
    <h4>2. Einsatz von Cookies</h4>
    <p>
      Mit Ihrem Besuch auf unseren Web-Seiten können Daten auf Ihrem Computer gespeichert werden, sog. „Cookies“, die
      Ihnen die Nutzung unseres Internetangebotes erleichtern. Die meisten Browser akzeptieren Cookies automatisch. Sie
      haben die Möglichkeit, diese Funktion innerhalb Ihres Webbrowsers zu deaktivieren, dies kann technisch bedingt zu
      Einschränkungen der Bedienbarkeit unserer Seiten führen.<br /><br />
      Beim Aufruf einzelner Seiten werden so genannte temporäre Cookies verwendet. Diese Session-Cookies beinhalten
      keine personenbezogenen Daten und werden nach Ablauf der Sitzung mit dem Schließen Ihres Web-Browsers automatisch
      gelöscht.
    </p>
    <h4>3. Erhebung und Verarbeitung personenbezogener Daten</h4>
    <p>
      Bei der Anmeldung zu den Webdiensten ist die Angabe personenbezogener Daten wie Name und E-Mail-Adresse
      erforderlich. Diese Daten sind zur Nutzung der Webdienste erforderlich. Weitere personenbezogene Daten können im
      persönlichen Nutzerprofil freiwillig ergänzt und jederzeit wieder gelöscht werden. Diese Daten können für die
      Zusammenarabeit mit anderen Nutzern hilfreich sein.<br /><br />
      Die an uns übermittelten personenbezogenen Daten werden nur zur Korrespondenz mit Ihnen und nur für den Zweck
      verarbeitet, zu dem Sie uns die Daten zur Verfügung gestellt haben. Ihre Daten werden von uns nicht an Dritte
      weitergegeben, es sei denn, dass wir dazu gesetzlich verpflichtet wären oder Sie uns vorab Ihre Zustimmung gegeben
      haben.<br /><br />
      Sämtliche personenbezogene Daten werden bei uns nur solange gespeichert, wie dies für den jeweiligen Zweck
      erforderlich ist oder wir gesetzlich zur Speicherung verpflichtet sind.<br /><br />
      Wenn Sie uns personenbezogene Daten überlassen haben, können Sie diese jederzeit wieder löschen lassen. Dazu
      wenden Sie sich an den/die Datenschutzbeauftragte (siehe oben).<br /><br />
    </p>
    <h4>4. Einwilligung in weitergehende Datennutzung</h4>
    <p>
      Mit Angabe der erfragten Daten und Übersendung willigen Sie in deren Verarbeitung ein. Sie können diese
      Einwilligung mit Wirkung für die Zukunft jederzeit widerrufen. Auch hierzu wenden Sie sich bitte an den/die
      Datenschutzbeauftragte (siehe oben).
    </p>
    <h4>5. Datensicherheit</h4>
    <p>
      Die Hochschule Worms setzt technische und organisatorische Sicherheitsmaßnahmen ein, um durch uns verwalteten
      personenbezogenen Daten gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den
      Zugriff unberechtigter Personen zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen
      Entwicklung fortlaufend verbessert.
    </p>
    <h4>6. Links zu Webseiten anderer Anbieter</h4>
    <p>
      Soweit über Querverweise (Links) auf Inhalte anderer Anbieter verwiesen wird, kann deren Datenerhebung und
      Datennutzung nach anderen als den oben dargestellten Grundsätzen erfolgen. Hinweise zur Verantwortung für die
      bereitgestellten Inhalte einer Webseite finden Sie im jeweiligen Impressum der Webseite.
    </p>
    <h4>7. Auskunftsrecht und Kontaktdaten</h4>
    <p>
      Sollten Sie mit der Speicherung Ihrer personenbezogenen Daten nicht mehr einverstanden oder diese unrichtig
      geworden sein, werden wir auf eine entsprechende Weisung hin die Löschung oder Sperrung Ihrer Daten veranlassen
      oder die notwendigen Korrekturen vornehmen (soweit dies nach dem geltendem Recht möglich ist).<br />
      Auf Wunsch erhalten Sie unentgeltlich Auskunft über alle personenbezogenen Daten, die wir über Sie gespeichert
      haben.
    </p>
    <h4>8. Aktualität und Gültigkeit der Datenschutzerklärung</h4>
    <p>
      Durch die Weiterentwicklung unserer Webseiten oder die Implementierung neuer Technologien kann es notwendig
      werden, diese Datenschutzerklärung zu ändern. Die Hochschule Worms behält sich vor, die Datenschutzerklärung
      jederzeit mit Wirkung für die Zukunft zu ändern. Wir empfehlen Ihnen, sich die aktuelle Datenschutzerklärung von
      Zeit zu Zeit erneut durchzulesen.<br />
      <strong>Bitte beachten: </strong>Für die Internetpräsenzen anderer Bereiche der Hochschule Worms (Fachbereiche,
      Institute, Fachschaften, etc.) gelten ggf. eigene Speicherregelungen. Diese sind in den jeweiligen
      Datenschutzerklärungen der einzelnen Internetpräsenzen erläutert.
    </p>
    <h4>9. Rechte für Betroffene gemäß DSGVO</h4>
    <p>
      <strong>Artikel 13 DSGVO, Informationsrecht</strong><br />
      Sie haben das Recht, über die Kontaktdaten des Verantwortlichen, den Zweck jeder einzelnen Art der
      Datenverarbeitung und deren Dauer informiert zu werden. Weiterhin müssen sie über ihre Rechte aufgeklärt
      werden.<br /><br />
      <strong>Artikel 15 DSGVO, Auskunftsrecht</strong><br />
      Sie haben das Recht, Auskunft darüber zu verlangen, ob und inwieweit Ihre personenbezogenen Daten verarbeitet
      werden (Verarbeitungszweck, Empfänger, Speicherdauer, etc.).<br /><br />
      <strong>Artikel 16 DSGVO, Recht auf Berichtigung</strong><br />
      Sie haben das Recht, die Berichtigung Ihrer gespeicherten Daten zu verlangen, sofern diese unrichtig oder
      unvollständig sein sollten. Dies umfasst das Recht auf Vervollständigung.<br /><br />
      <strong>Artikel 17 DSGVO, Recht auf Löschung</strong><br />
      Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten zu verlangen, sofern nicht übergeordnete
      Rechtsgründe dem Wunsch entgegenstehen.<br /><br />
      <strong>Artikel 18 DSGVO, Recht auf Einschränkung der Verarbeitung</strong><br />
      Sie haben das Recht, die Verarbeitung ihrer personenbezogenen Daten einschränken zu lassen. Dies ist etwa dann
      möglich, wenn Ihre Daten unrichtig erfasst sind oder die Datenverarbeitung unrechtmäßig erfolgt. Im Falle der
      Einschränkung der Verarbeitung dürfen die Daten nur noch in eng umgrenzten Fällen verarbeitet werden.<br /><br />
      <strong>Artikel 20 DSGVO, Recht auf Datenübertragbarkeit</strong><br />
      Sie haben das Recht, die Herausgabe der Sie betreffenden Daten in einem gängigen elektronischen, maschinenlesbaren
      Datenformat an Sie oder an einen von Ihnen zu benennenden Verantwortlichen zu verlangen, wenn Sie diese Daten
      selbst bereitgestellt haben.<br /><br />
      <strong>Artikel 21 DSGVO, Widerspruchsrecht</strong><br />
      Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit der Verarbeitung sie
      betreffender personenbezogener Daten für die Zukunft zu widersprechen.<br /><br />
      <strong>Artikel 22 DSGVO, Recht, nicht ausschließlich automatisierter Entscheidung</strong><br />
      Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling
      – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in
      ähnlicher Weise erheblich beeinträchtigt.<br /><br />
      <strong>Artikel 77 DSGVO, Beschwerderecht</strong><br />
      Sie haben das Recht, sich wegen eventueller Verstöße gegen datenschutzrechtliche Vorschriften jederzeit an die
      zuständige Aufsichtsbehörde zu wenden. Die entsprechende Adresse finden Sie am Anfang des Dokuments.
    </p>
  </div>
</template>
