<template>
  <div class="header-container">
    <div class="nav-container">
      <div class="banner">
        <img class="logo" src="@/assets/wetterstation-banner.svg" alt="Logo" />
        <div @click="openMobileNav" class="mdi" :class="headerNavOpen ? `mdi-close` : `mdi-menu`"></div>
      </div>
      <div v-if="headerNavOpen" class="overlay">
        <nav>
          <ul>
            <li v-for="(navItem, index) in navItems" :key="index">
              <router-link
                @click="openMobileNav"
                :to="{ name: navItem.name, query: { ...$router.currentRoute.value.query } }"
              >
                {{ navItem.linkText }}
              </router-link>
            </li>
          </ul>
        </nav>
        <footer-component :mobileNavOpen="headerNavOpen" />
      </div>
    </div>
  </div>
</template>

<script>
import FooterComponent from "@/components/layout/FooterComponent";

export default {
  components: {
    FooterComponent,
  },
  data() {
    return {
      headerNavOpen: false,
      navItems: [
        {
          linkText: "Wetter",
          name: "wetter",
        },
        {
          linkText: "Projekt",
          name: "projekt",
        },
        {
          linkText: "Galerie",
          name: "galerie",
        },
        {
          linkText: "Daten",
          name: "daten",
        },
        {
          linkText: "Team",
          name: "team",
        },
      ],
    };
  },
  methods: {
    openMobileNav() {
      this.headerNavOpen = !this.headerNavOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
div,
nav,
footer {
  z-index: 15;
}
.nav-container {
  position: relative;
  max-width: 1024px;
  margin: auto;
  @media screen and (min-width: $desktopSize) {
    padding: 0 16px 0 19px;
  }
}
.banner {
  height: $headerNavHeightMobile;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
.header-container {
  z-index: 15;
  position: fixed;
  background: $hs-blue-primary;
  left: 0;
  right: 0;
  top: 0;
}
.logo {
  height: 60px;
}
.mdi {
  justify-self: end;
  color: $white;
  margin-right: 16px;
}
.mdi::before {
  font-size: 40px;
}
nav {
  background: $white;
  position: fixed;
  display: flex;
  align-items: center;
  top: $headerNavHeightMobile;
  right: 0;
  left: 0;
  bottom: calc($footerHeightMobile - 1px);
  @media screen and (min-width: $tabletSize) {
    bottom: calc($footerHeightTabletUp - 1px);
  }
}
ul {
  list-style-type: none;
  padding: none;
}
li {
  margin: 24px 0;
}
a {
  text-decoration: none;
  font-size: 24px;
}
</style>
