<template>
  <div>
    <h3 class="section-heading">Benutzerdefiniert</h3>
    <div class="form-container">
      <div class="inputBox dateBox dateBox1" :class="this.wrongStartDate ? 'wrongBox' : ''">
        <span class="inputTitle">Anfangsdatum</span>
        <input class="input" ref="picker1" v-model="settings.from" />
        <hr class="selectionLine" />
      </div>

      <div class="inputBox dateBox dateBox2" :class="this.wrongEndDate ? 'wrongBox' : ''">
        <span class="inputTitle">Enddatum</span>
        <input class="input" ref="picker2" v-model="settings.to" />
        <hr class="selectionLine" />
      </div>

      <button
        @click="getPeriodData"
        class="submit-button"
        :disabled="settings.from == '' || settings.to == '' || settings.loading"
        :id="'go'"
      >
        <span>Abrufen</span>
      </button>
    </div>
    <div v-if="settings.loading" class="loading-component-wrapper">
      <loading-component message="" />
    </div>
    <div class="period-container">
      <div v-for="(item, index) in periodData" :key="index" class="period-item">
        <h4>{{ item.title }}</h4>
        <div v-if="Object.keys(item.data).length === 0">Keine Daten vorhanden</div>
        <div v-else>
          <p class="data-container" v-if="item.title === 'Sonneneinstrahlung' || item.title === 'UV-Index'">
            Schnitt: {{ item.data.avg.value }} {{ item.unit }}
          </p>
          <div class="data-container" v-else>
            <p>Min: {{ item.data.min.value }} {{ item.unit }}</p>
            <p>
              <span class="date">{{ new Date(item.data.min.date).toLocaleDateString("de-DE") }}</span>
              <span class="time"> {{ new Date(item.data.min.date).toLocaleTimeString("de-DE") }}</span>
            </p>
          </div>
          <div class="data-container">
            <p>Max: {{ item.data.max.value }} {{ item.unit }}</p>
            <p>
              <span class="date">{{ new Date(item.data.max.date).toLocaleDateString("de-DE") }}</span>
              <span class="time"> {{ new Date(item.data.max.date).toLocaleTimeString("de-DE") }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="full-statistics-wrapper">
      <h3 class="section-heading">Allgemein</h3>
      <button
        @click="toggleFullStatistic"
        class="submit-button all-data-button"
        :class="showStatistic ? 'removeData' : ''"
      >
        {{ showStatistic ? "Alle Daten verbergen" : "Alle Daten abrufen" }}
      </button>
      <full-statistics-component v-if="showStatistic" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FullStatisticsComponent from "@/components/data/FullStatisticsComponent";
import LoadingComponent from "@/components/miscellaneous/LoadingComponent";
import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js";
require("flatpickr/dist/themes/light.css");
flatpickr.localize(German);

const { DateTime } = require("luxon");
export default {
  components: {
    FullStatisticsComponent,
    LoadingComponent,
  },
  data() {
    return {
      settings: {
        data: {},
        from: DateTime.now().minus({ days: 1 }).toISO(),
        to: DateTime.now().toISO(),
        intervalUnit: "min",
        interval: "1",
        take: 30,
        skip: 0,
        loading: true,
      },
      periodData: {
        temp: { title: "Temperatur", data: {}, unit: "˚C" },
        rain: { title: "Regenrate", data: {}, unit: "mm/h" },
        baro: { title: "Luftdruck", data: {}, unit: "hPa" },
        humidity: { title: "Luftfeuchtigkeit", data: {}, unit: "%" },
        wind: { title: "Windgeschwindigkeit", data: {}, unit: "km/h" },
        sun: { title: "Sonneneinstrahlung", data: {}, unit: "W/qm" },
        uv: { title: "UV-Index", data: {}, unit: "" },
      },
      showStatistic: false,
    };
  },

  methods: {
    toggleFullStatistic() {
      this.showStatistic = !this.showStatistic;
    },
    getPeriodData() {
      this.settings.loading = true;

      axios
        .get(`/statistics/ranged`, {
          params: {
              from: this.settings.from,
              to: this.settings.to,
            },
        })
        .then(({ data }) => {
          if (data) {
            console.log(data)
            this.periodData.baro.data = data.baro;
            this.periodData.humidity.data = data.humidity;
            this.periodData.rain.data = data.rain;
            this.periodData.sun.data = data.sun.rad;
            this.periodData.uv.data = data.sun.uv;
            this.periodData.temp.data = data.temp;
            this.periodData.wind.data = data.wind;
            this.settings.loading = false;
          }
        })
        .catch((error) => {
          console.error(error);
          this.settings.loading = false;
        });
    },
  },
  mounted() {
    this.getPeriodData();

    flatpickr(this.$refs.picker1, {
      disableMobile: "true",
      altInput: true,
      altFormat: "d.m.Y",
      enable: [
        {
          from: "2007-07-29T00:00",
          to: new Date().toISOString(),
        },
      ],
    });

    flatpickr(this.$refs.picker2, {
      disableMobile: "true",
      altInput: true,
      altFormat: "d.m.Y",
      enable: [
        {
          from: "2007-07-29T00:00",
          to: new Date().toISOString(),
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  display: grid;
  width: 100%;
  grid-gap: 16px 8px;
  grid-template-columns: repeat(2, 1fr);
  margin: auto;
  @media screen and (min-width: $tabletSize) {
    grid-template-columns: repeat(3, 1fr);
    width: 600px;
  }
}
.section-heading {
  margin: 60px;
}
.input {
  border: 0;
  background-color: $lightest-gray;
  font-size: 11px;
  width: 100%;
}
.inputBox {
  height: 64px;
  padding: 8px;
  box-sizing: border-box;
  background-color: $lightest-gray;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  border-radius: 8px 8px 0px 0px;
}
.inputTitle {
  font-size: 12px;
  margin-bottom: 8px;
  display: block;
}

.selectionLine {
  height: 1.5px;
  margin-bottom: 4px;
  border-width: 0;
  color: gray;
  background-color: gray;
}
.submit-button {
  grid-column: 1/3;
  width: 100%;
  @media screen and (min-width: $tabletSize) {
    grid-column: 3/4;
  }
}
.all-data-button {
  margin-top: 40px;
  @media screen and (min-width: $tabletSize) {
    width: fit-content;
    display: block;
    margin: auto;
    padding: 0 16px;
  }
}
.removeData {
  background: $orange;
}
.period-container {
  display: grid;
  grid-gap: 16px 8px;
  grid-template-columns: 1fr 1fr;
  margin-top: 48px;
  @media screen and (min-width: $tabletSize) {
    grid-gap: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media screen and (min-width: $desktopSize) {
    grid-template-columns: 1fr 1fr;
  }
}
.period-item {
  min-width: 140px;
  background: $hs-blue-primary;
  color: $white;
  padding: 8px;
  @media screen and (min-width: $tabletSize) {
    width: 23%;
    padding: 8px;
    transition: all 0.2s;
  }
  h3 {
    word-break: break-all;
  }
}
.data-container {
  padding: 4px;
}
.date {
  margin-right: 8px;
}
.full-statistics-wrapper {
  margin-top: 80px;
}
.loading-component-wrapper {
  margin-top: 80px;
}
</style>
