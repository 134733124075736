<template>
  <div class="header-container">
    <div class="logo-extender"></div>
    <div class="nav-container">
      <div class="banner">
        <div class="logo">
          <div class="border-container">
            <img src="@/assets/wetterstation-banner.svg" alt="Logo" />
          </div>
        </div>
      </div>
      <nav>
        <ul>
          <li v-for="(navItem, index) in navItems" :key="index">
            <router-link :to="{ name: navItem.name, query: { ...$router.currentRoute.value.query } }">
              {{ navItem.linkText }}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      navItems: [
        {
          linkText: "Wetter",
          name: "wetter",
        },
        {
          linkText: "Projekt",
          name: "projekt",
        },
        {
          linkText: "Galerie",
          name: "galerie",
        },
        {
          linkText: "Daten",
          name: "daten",
        },
        {
          linkText: "Team",
          name: "team",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
div,
nav,
footer {
  z-index: 15;
}
.header-container {
  z-index: 15;
  position: fixed;
  background: $hs-blue-primary;
  left: 0;
  right: 0;
  top: 0;
}
.logo-extender {
  display: none;
  width: 16px;
  @media screen and (min-width: $desktopSize) {
    display: block;
    width: calc((100vw - 1024px + 48px) / 2);
    position: fixed;
    height: 128px;
    background: $hs-blue-primary;
  }
}
.nav-container {
  position: relative;
  max-width: 1024px;
  margin: auto;
  padding: 0 16px 0 16px;
  display: grid;
  grid-template-columns: calc(200px + 16px) 1fr;
  @media screen and (min-width: $desktopSize) {
    grid-template-columns: calc(370px + 16px) 1fr;
  }
}
.banner {
  position: relative;
}
.logo {
  position: absolute;
  height: 88px;
  right: -6px;
  left: 0;
  background: $white;
  @media screen and (min-width: $desktopSize) {
    height: 126px;
  }
}
.border-container {
  width: calc(370px + 6px);
  border-left: solid $hs-blue-primary 3px;
  border-bottom: solid $hs-blue-primary 3px;
  @media screen and (min-width: $tabletSize) {
    border-bottom: solid $hs-blue-primary 2px;
  }
  @media screen and (min-width: $desktopSize) {
    border-bottom: solid $hs-blue-primary 3px;
  }
}
img {
  height: 81px;
  @media screen and (min-width: $desktopSize) {
    height: 120px;
  }
}
ul {
  list-style-type: none;
  padding: 16px 16px 16px 35px;
  display: flex;
  justify-content: space-between;
}
li {
  transition: all 0.2s;
}
li:hover {
  transform: scale(1.2) translateY(5px);
}
a {
  text-decoration: none;
  font-size: 24px;
  color: $white;
}
</style>
