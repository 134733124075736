<template>
  <div class="graphView">
    <div
      v-for="(ChartComponent, index) in charts"
      :key="ChartComponent.id"
      :id="ChartComponent.id"
      style="min-height: 400px"
    >
      <button class="button removeGraphButton" v-if="index != 0" @click="removeChart(ChartComponent)">
        <span>- Graph entfernen</span>
      </button>
      <chart-component class="graph" :id="ChartComponent.id" />
    </div>
    <button class="button addGraphButton" @click="createChart">
      <span>+ Weiterer Graph</span>
    </button>
  </div>
</template>

<script>
import ChartComponent from "@/components/data/ChartComponent";
export default {
  components: {
    ChartComponent,
  },
  data() {
    return {
      charts: [],
    };
  },
  methods: {
    createChart() {
      let id = this.charts.length == 0 ? 0 : this.charts[this.charts.length - 1].id + 1;
      this.charts.push({
        id: id,
        chartComponent: ChartComponent,
      });
      return this.charts.find((element) => element.id == id);
    },
    removeChart(c) {
      const index = this.charts.indexOf(c);
      this.charts.splice(index, 1);
    },
  },
  mounted() {
    this.createChart();
    this.charts[0];
  },
};
</script>

<style scoped lang="scss">
.graphView {
  width: auto;
  @media screen and (min-width: $desktopSize) {
    display: block;
    width: 992px;
    margin: auto;
  }
}
.button {
  display: block;
  margin: 20px auto;
  font-size: 24px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  padding: 16px;
  border-width: 0;
  border-radius: 4px;
  color: $white;
  transition: background-color 0.3s;
}
.addGraphButton {
  background-color: $green;
}
.addGraphButton:active {
  background-color: $green-transparent;
}
.addGraphButton:hover,
addGraphButton:focus {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);
}
.removeGraphButton {
  background-color: $orange;
  margin-bottom: 40px;
}
.removeGraphButton:active {
  background-color: $orange-transparent;
}
.removeGraphButton:hover,
addGraphButton:focus {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);
}
</style>
