<template>
  <div class="container">
    <img :src="this.currentImageURL" alt="Wetterkamera" id="currentImage" />
    <compass-component :rotation="rotation" />
  </div>
</template>

<script>
import axios from "axios";
import CompassComponent from "@/components/home/CompassComponent";
export default {
  components: {
    CompassComponent,
  },
  data() {
    return {
      rotation: 90,
      currentImageURL: process.env.VUE_APP_BACKEND_URL + '/images/current/webcam.jpg',
      timeStamp: 0,
    };
  },
  sockets: {
    currentCam: function () {
      this.timeStamp = new Date().getTime();
      this.currentImageURL = process.env.VUE_APP_BACKEND_URL + '/images/current/webcam.jpg' + "?" + this.timeStamp;
      this.$forceUpdate()
      this.updateRotation();
    },
  },
  methods: {
    updateRotation() {
      axios
        .get("/camera/direction")
        .then((response) => {
          if (response.status == 200) {
            this.rotation = response.data.direction;
          } else {
            console.warn("/camera/direction Response status " + response.status);
          }
        })
        .catch((error) => {
          console.error("API /camera/direction failed with error: ", error);
        });
    },
  },
  mounted() {
    this.updateRotation()
  }
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100vw;
  margin: 0 -16px;
  overflow: hidden;
  @media screen and (min-width: $desktopSize) {
    width: 100%;
    margin: 0;
  }
}
#currentImage {
  width: 100%;
}
</style>
