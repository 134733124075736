<template>
  <div>
    <h2>Galerie</h2>
    <tab-nav-component :navigation="navigation" />
    <keep-alive>
      <today-component v-if="$route.query.galerie === 'heute' || $route.query.galerie === undefined" />
    </keep-alive>
    <keep-alive>
      <highlights-component v-if="$route.query.galerie === 'tolledinge'" />
    </keep-alive>
    <keep-alive>
      <range-component v-if="$route.query.galerie === 'zeitraum'" />
    </keep-alive>
    <keep-alive>
      <timelapse-component v-if="$route.query.galerie === 'zeitraffer'" />
    </keep-alive>
  </div>
</template>

<script>
import TabNavComponent from "@/components/layout/TabNavComponent";
import HighlightsComponent from "@/components/gallery/HighlightsComponent";
import RangeComponent from "@/components/gallery/RangeComponent";
import TodayComponent from "@/components/gallery/TodayComponent";
import TimelapseComponent from "@/components/gallery/TimelapseComponent";

export default {
  components: {
    TabNavComponent,
    HighlightsComponent,
    RangeComponent,
    TodayComponent,
    TimelapseComponent,
  },
  data() {
    return {
      navigation: [
        { name: "galerie", param: "heute", linkText: "Heute" },
        { name: "galerie", param: "tolledinge", linkText: "Tolle Dinge" },
        { name: "galerie", param: "zeitraum", linkText: "Zeitraum" },
        { name: "galerie", param: "zeitraffer", linkText: "Zeitraffer" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: center;
  font-weight: lighter;
  font-size: xxx-large;
}
</style>
