<template>
  <div>
    <h1>Impressum</h1>
    <p>Angaben zum Dienstanbieter der Webseiten nach dem Telemediengesetz</p>
    <h4>Verantwortliche und Ansprechpartner</h4>
    <h5>Ansprechpartner für Pflege und Aktualisierungen</h5>
    <p>
      Webredaktion des Studiengangs Angewandte Informatik (ai.it.hs-worms.de)<br />Für Kommentare, Anmerkungen oder
      Fehlerkorrekturen schreiben Sie bitte eine E-Mail an die Webredaktion h.thielen@it.hs-worms.de.
    </p>
    <h5>Verantwortlich für das Webangebot des Studiengangs Angewandte Informatik</h5>
    <p>
      Prof. Dr.-Ing. Herbert Thielen<br />h.thielen@it.hs-worms.de<br />Hochschule Worms<br />Fachbereich Informatik<br />Raum
      N317
    </p>
    <h4>Angaben zum Dienstanbieter</h4>
    <p>
      Hochschule Worms University of Applied Sciences<br />Erenburgerstraße 19<br />67549 Worms<br />Deutschland<br /><br />Telefon:
      +49 6241/509-0<br />Telefax: +49 6241/509-222<br />E-Mail: kontakt@hs-worms.de<br />Internet: www.hs-worms.de<br /><br />Umsatzsteueridentifikationsnummer
      gemäß §27a Umsatzsteuergesetz: DE813351149<br /><br />Die Hochschule Worms ist eine Körperschaft des Öffentlichen
      Rechts. Sie wird durch den Präsidenten Prof. Dr. Jens Hermsdorf gesetzlich vertreten.
    </p>
    <h4>Zuständige Aufsichtsbehörde</h4>
    <p>
      Ministerium für Wissenschaft,<br />Weiterbildung und Kultur (MWWK)<br />des Landes Rheinland-Pfalz<br />Mittlere
      Bleiche 61<br />55116 Mainz<br />Deutschland
    </p>
    <h4>Haftung / Disclaimer</h4>
    <p>
      Der Anbieter versucht, die Richtigkeit und Aktualität der auf dieser Internetpräsenz bereitgestellten
      Informationen zu gewährleisten. Trotzdem können Fehler und Unklarheiten nicht vollständig ausgeschlossen werden.
      Der Anbieter übernimmt daher keine Gewähr für die Aktualität, Richtigkeit, Vollständigkeit oder Qualität der
      veröffentlichten Informationen.<br />Für Schäden materieller oder immaterieller Art, die durch die Nutzung oder
      Nichtnutzung der dargebotenen Informationen unmittelbar oder mittelbar verursacht werden, haftet der Anbieter
      nicht, sofern ihm nicht vorsätzliches oder grob fahrlässiges Verschulden angelastet werden kann.<br />Der Anbieter
      behält es sich vor, Teile des Internet-Angebots oder das gesamte Angebot ohne Vorankündigung zu verändern, zu
      ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig aus dem Internet zu entfernen.<br />Das
      Angebot des Anbieters enthält direkte oder indirekte Verknüpfungen bzw. Verlinkungen zu externen Webseiten
      Dritter, auf deren Inhalte der Anbieter keinen Einfluss hat. Die Verantwortlichkeit für verlinkte Inhalte liegt
      bei dem Betreiber dieser Webseiten. Zum Zeitpunkt der Verlinkung wurde eine Überprüfung auf mögliche
      Rechtsverstöße vorgenommen, wobei keine rechtswidrigen oder anstößigen Inhalte erkennbar waren. Sollten auf den
      verlinkten Seiten Rechtswidriges oder Anstößiges auftauchen, distanziert sich die Hochschule Worms ausdrücklich
      von diesen Inhalten und entfernt bei Bekanntwerden die jeweilige Verlinkung unverzüglich.
    </p>
    <h4>Datenschutzerklärung</h4>
    <p>Die Datenschutzerklärung finden Sie unter <a href="http://wetter.hs-worms.de/legal">Datenschutz</a></p>
    <h4>Copyright</h4>
    <p>
      Die Webseiten der Hochschule Worms sind urheberrechtlich geschützt. Alle Texte, Grafiken, Tabellen oder Bilder
      dürfen ohne vorherige Zustimmung der Hochschule Worms nicht weiterverwendet werden. Die Urheberrechte der
      verwendeten Bilder entnehmen Sie bitte den Informationen auf der jeweiligen Seite.
    </p>
  </div>
</template>
