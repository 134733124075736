<template>
  <nav>
    <ul :class="this.navLength3 ? 'ul3' : ''">
      <li v-for="(navItem, index) in navigation" :key="index">
        <router-link
          class="item"
          :class="this.isActive(navItem, index) ? 'activeItem' : ''"
          :to="{ name: navItem.name, query: { ...$router.currentRoute.value.query, [navItem.name]: navItem.param } }"
        >
          {{ navItem.linkText }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useRoute } from "vue-router";

export default {
  props: {
    navigation: Array, // eg: [{ name: "wetter", param: "moewencam", linkText: "Möwencam" }]
  },
  methods: {
    isActive(item, index) {
      const route = useRoute();
      // display item as selected if no query parameters are set
      if (!Object.keys(route.query).includes(`${this.navigation[0]?.name}`) && index === 0) {
        return true;
      }
      return Object.values(route.query).includes(item.param);
    },
  },
  computed: {
    navLength3() {
      return this.navigation.length == 3;
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  margin: 48px 0px;
}
ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 0px;
  grid-gap: 16px;
  overflow: hidden;
  @media screen and (min-width: $tabletSize) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
ul li {
  @media screen and (min-width: $tabletSize) {
    border-right: 2px solid $light-gray;
  }
}
ul li:last-child {
  @media screen and (min-width: $tabletSize) {
    border-right: none;
  }
}
.ul3 {
  @media screen and (max-width: $tabletSize) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
li {
  list-style-type: none;
  @media screen and (min-width: $tabletSize) {
    flex: 1;
  }
}
a {
  @media screen and (min-width: $desktopSize) {
    transition: all 0.2s;
  }
}
a:hover {
  @media screen and (min-width: $desktopSize) {
    transform: scale(1.2) translateY(7px);
  }
}
.item {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
  border: 1px solid $hs-blue-primary;
  text-decoration: none;
  font-size: large;
  background-color: $lightest-gray;
  text-align: center;
  @media screen and (min-width: $tabletSize) {
    border: none;
    background-color: transparent;
    color: $medium-gray;
    font-size: x-large;
  }
}
.activeItem {
  background-color: $hs-blue-primary;
  color: $white;
  text-decoration: underline;
  @media screen and (min-width: $tabletSize) {
    background-color: transparent;
    color: $dark-gray;
    transform: scale(1.2) translateY(7px);
  }
}
</style>
