import {
  createRouter,
  createWebHistory
} from "vue-router";
import MainPageView from "@/views/MainPageView";
import ImpressumView from "@/views/legal/ImpressumView";
import DataPolicyView from "@/views/legal/DataPolicyView";
import NotFoundView from '../views/NotFoundView.vue';

const routes = [{
    path: "/",
    components: {
      default: MainPageView,
    },
    children: [{
        name: "wetter",
        path: "/#wetter",
        components: {
          default: MainPageView,
        },
      },
      {
        name: "projekt",
        path: "/#projekt",
        components: {
          default: MainPageView,
        },
      },
      {
        name: "galerie",
        path: "/#galerie",
        components: {
          default: MainPageView,
        },
      },
      {
        name: "daten",
        path: "/#daten",
        components: {
          default: MainPageView,
        },
      },
      {
        name: "team",
        path: "/#team",
        components: {
          default: MainPageView,
        },
      },
    ],
  },
  {
    path: "/impressum",
    components: {
      default: ImpressumView,
      home: ImpressumView,
    },
  },
  {
    path: "/datenschutz",
    components: {
      default: DataPolicyView,
      home: DataPolicyView,
    },
  },
  //Not Found must be last one
  {
    path: "/:pathMatch(.*)*",
    components: {
      default: NotFoundView,
      home: NotFoundView,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    return from.path !== to.path ? {
      el: `#${to.name}`,
      top: 150,
      behavior: "smooth"
    } : {};
  },
});

export default router;