<template>
  <div class="grid-container">
    <img src="@/assets/undraw_no_data.svg" width="auto" />
    <div class="text">
      <h2>404 - Not Found</h2>
      <p>Die Seite die Sie besuchen wollten wurde nicht gefunden.</p>
      <p>
        Die Wetterseite der HS Worms wurde kürzlich umgebaut. Zuvor gespeicherte Links können unter Umständen nicht mehr
        funktionieren.
      </p>
      <a href="https://wetter.hs-worms.de">Zur neuen Seite</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.grid-container {
  @media screen and (min-width: $desktopSize) {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: minmax(320px, 500px) minmax(600px, 1fr);
    margin-top: 64px;
  }
  margin-bottom: 32px;
  justify-items: center;
}

img {
  width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: $desktopSize) {
    width: 80%;
  }
}
</style>
